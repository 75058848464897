"use client";

import * as React from "react";
import { ChevronsUpDown } from "lucide-react";
import { Dialog } from "@radix-ui/react-dialog";

import { cn } from "@/lib/utils";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { type ROLE_VALUES } from "@cloudifybiz/lighthouse-core/constants";
import { type Company } from "./types";
import useCompany from "@/hooks/useCompany";
import { CompanyCommandList } from "./company-command-list";
import { CompanyCreationDialog } from "./company-creation-dialog";

type Groups = Array<{
  label: string;
  companies: Array<{
    label: string;
    value: string;
    role: (typeof ROLE_VALUES)[number];
    logo?: string;
  }>;
}>;

type PopoverTriggerProps = React.ComponentPropsWithoutRef<
  typeof PopoverTrigger
>;

interface CompanySwitcherProps extends PopoverTriggerProps {
  groups: Groups;
}

const getCompanyWithSlugValueFromGroups = (groups: Groups, slug: string) => {
  return groups
    .map((group) => group.companies)
    .flat()
    .find((company) => company.value === slug);
};

export default function CompanySwitcher({
  className,
  groups,
}: CompanySwitcherProps) {
  const [open, setOpen] = React.useState(false);
  const [showNewCompanyDialog, setShowNewCompanyDialog] = React.useState(false);
  const [companySlug, setCompany] = useCompany();
  const [selectedCompany, setSelectedCompany] = React.useState<
    Company | undefined
  >();

  React.useEffect(() => {
    if (companySlug) {
      setSelectedCompany(
        getCompanyWithSlugValueFromGroups(groups, companySlug),
      );
    }
  }, [companySlug, groups]);

  return (
    <>
      <Dialog>
        <Popover open={open} onOpenChange={setOpen}>
          <PopoverTrigger asChild>
            <Button
              variant="outline"
              size="sm"
              role="combobox"
              aria-expanded={open}
              aria-label="Select a company"
              className={cn("w-[200px] justify-between", className)}
            >
              {selectedCompany && (
                <>
                  <Avatar className="mr-2 h-5 w-5">
                    <AvatarImage
                      src={
                        selectedCompany.logo ??
                        `https://avatar.vercel.sh/${selectedCompany.value}.png`
                      }
                      alt={selectedCompany.label}
                    />
                    <AvatarFallback>LH</AvatarFallback>
                  </Avatar>
                  {selectedCompany.label}
                </>
              )}
              {!selectedCompany && "Select a company"}
              <ChevronsUpDown className="ml-auto h-4 w-4 shrink-0 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-[200px] p-0">
            <CompanyCommandList
              groups={groups}
              onCompanySelect={(companySlug) => {
                setCompany(companySlug);
                setOpen(false);
              }}
              onNewCompanyClick={() => {
                setOpen(false);
                setShowNewCompanyDialog(true);
              }}
            />
          </PopoverContent>
        </Popover>
      </Dialog>
      <CompanyCreationDialog
        open={showNewCompanyDialog}
        onOpenChange={setShowNewCompanyDialog}
      />
    </>
  );
}
