"use client";

import * as React from "react";
import { Check, PlusCircle } from "lucide-react";

import { cn } from "@/lib/utils";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "@/components/ui/command";
import { ROLE_NAMES } from "@cloudifybiz/lighthouse-core/constants";
import {
  TooltipProvider,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { type Company, type Groups } from "./types";

type CompanyCommandListProps = {
  groups: Groups;
  onCompanySelect?: (companySlug: string) => void;
  onNewCompanyClick?: () => void;
  selectedCompany?: Company;
};

export const CompanyCommandList = ({
  groups,
  onCompanySelect,
  onNewCompanyClick,
  selectedCompany,
}: CompanyCommandListProps) => {
  return (
    <Command>
      <CommandList>
        <CommandInput placeholder="Search company..." />
        <CommandEmpty>No company found.</CommandEmpty>
        {groups.map((group) => (
          <CommandGroup key={group.label} heading={group.label}>
            {group.companies.map((company) => (
              <TooltipProvider key={company.value}>
                <Tooltip>
                  <TooltipTrigger className="w-full">
                    <CommandItem
                      onSelect={() => {
                        if (onCompanySelect) {
                          onCompanySelect(company.value);
                        }
                      }}
                      className="text-sm"
                    >
                      <Avatar className="mr-2 h-5 w-5">
                        <AvatarImage
                          src={
                            company.logo ??
                            `https://avatar.vercel.sh/${company.value}.png`
                          }
                          alt={company.label}
                        />
                        <AvatarFallback>{company.value[0]}</AvatarFallback>
                      </Avatar>
                      {company.label}
                      <Check
                        className={cn(
                          "ml-auto h-4 w-4",
                          selectedCompany &&
                            selectedCompany.value === company.value
                            ? "opacity-100"
                            : "opacity-0",
                        )}
                      />
                    </CommandItem>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>{ROLE_NAMES[company.role]}</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            ))}
          </CommandGroup>
        ))}
      </CommandList>
      <CommandSeparator />
      <CommandList>
        <CommandGroup>
          <CommandItem onSelect={onNewCompanyClick}>
            <PlusCircle className="mr-2 h-5 w-5" />
            Create Company
          </CommandItem>
        </CommandGroup>
      </CommandList>
    </Command>
  );
};
