"use client";

import {
  Building,
  LayoutDashboard,
  LogOut,
  Settings,
  Workflow,
} from "lucide-react";
import useCompany from "@/hooks/useCompany";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useRouter } from "next/navigation";

export function UserNav({
  name,
  email,
  avatar,
}: {
  name?: string | null;
  email?: string | null;
  avatar?: string | null;
}) {
  const router = useRouter();
  const [companySlug] = useCompany();
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="relative h-8 w-8 rounded-full">
          <Avatar className="h-8 w-8">
            <AvatarImage
              src={avatar ?? `https://avatar.vercel.sh/${email}.png`}
              alt={name ?? "User Profile Image"}
            />
            <AvatarFallback>
              {name
                ? name
                    .split(" ")
                    .map((part) => part[0])
                    .join("")
                    .toUpperCase()
                : "LC"}
            </AvatarFallback>
          </Avatar>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="end" forceMount>
        <DropdownMenuLabel className="font-normal">
          <div className="flex flex-col space-y-1">
            <p className="text-sm font-medium leading-none">{name}</p>
            <p className="text-xs leading-none text-muted-foreground">
              {email}
            </p>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem
            onClick={() => {
              router.push(`/${companySlug}/dashboard/overview`);
            }}
          >
            <LayoutDashboard className="mr-2 h-4 w-4" />
            <span>Dashboard</span>
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => {
              router.push(`/${companySlug}/settings/workflows`);
            }}
          >
            <Workflow className="mr-2 h-4 w-4" />
            <span>Your Workflows</span>
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => {
              router.push("/settings");
            }}
          >
            <Settings className="mr-2 h-4 w-4" />
            <span>Your Settings</span>
          </DropdownMenuItem>
          {companySlug && (
            <DropdownMenuItem
              onClick={() => {
                router.push(`/${companySlug}/settings`);
              }}
            >
              <Building className="mr-2 h-4 w-4" />
              <span>Company Settings</span>
            </DropdownMenuItem>
          )}
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          className="text-red-600 focus:text-red-600"
          onClick={() => {
            router.push("/api/auth/logout");
          }}
        >
          <LogOut className="mr-2 h-4 w-4" />
          <span>Log out</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
